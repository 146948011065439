.table-icon .toster-head {
  position: absolute;
  right: 0;
}

.table-bg .table tbody td .table-icon {
  position: relative;
}

.crypto_list_item:hover {
  cursor: pointer;
  background: gray;
}
