.identification .header {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.identification .header .arrow-bg i {
  color: #ffffff;
  font-size: 30px;
}
.identification .header h3 {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  margin-left: -40px;
  z-index: 9;
}
.identification .header .arrow-bg {
  background: #797979;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  z-index: 99;
}
.identification .nav {
  justify-content: space-between;
  border: 0;
  margin: 30px 0;
}
.identification .nav .nav-item .nav-link img {
  margin: auto;
  margin-bottom: 5px;
}
.identification .nav .nav-item .nav-link {
  width: 105px;
  height: 64px;
  text-align: center;
  padding: 0;
  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
  border: 2px solid #a0a0a0;
  border-radius: 8px;
}
.identification .form-control::placeholder {
  color: #ffffff;
}
.identification .upload-box i {
  font-weight: 500;
  font-size: 20px;
  color: #797979;
}
.identification .upload-box span {
  font-weight: 500;
  font-size: 15px;
  color: #797979;
}
.identification .upload-box {
  border: 2px dashed #797979;
  border-radius: 8px;
  text-align: center;
  padding: 1px 0 3px;
  position: relative;
  cursor: pointer;
}
.identification .form-control-md {
  width: 100%;
  color: #fff;
  opacity: 0;
  position: absolute;
  top: 0;
  cursor: pointer;
  left: 0;
}
.identification .next-btn i {
  font-size: 20px;
  margin-right: 8px;
}
.identification .next-btn {
  background: #3361a3;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  margin-top: 205px;
}
.identification .form-control {
  background: #797979;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}
.identification .nav .nav-item .nav-link.active {
  background: #58d86c;
  border: 2px solid #27253f;
  color: #000000;
}
.identification .nav .nav-item .nav-link.active .light {
  display: none;
}
.identification .nav .nav-item .nav-link.active .dark {
  display: block;
}
.identification .nav .nav-item .nav-link .light {
  display: block;
}
.identification .nav .nav-item .nav-link .dark {
  display: none;
}
