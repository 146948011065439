.balances_box {
  cursor: pointer;
}

.balances_box_image {
  width: 32px;
}

.balances_box_heading {
  color: #4ba9ed;
  font-size: 18px;
}
