.balance p {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  color: #797979;
}
.balance h6 {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.balance .balance-box p {
  color: #ffffff;
}
.balance .balance-box h4 {
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}
.balance .balance-box h2 span {
  /* color: rgba(255, 255, 255, 0.5); */
  color: FFFFFF;
}
.balance .balance-box h2 {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}
/* .balance .balance-box img {
    width: 32px;
} */
.balance .balance-box {
  background: #797979;
  border-radius: 8px;
  padding: 15px 20px;
}
.balance .next-btn {
  background: #1f1e32;
  border: 1px solid #27253f;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  color: #797979;
}
.balance-wrap {
  display: inline-block;
  width: 130px;
  overflow-wrap: break-word;
}
