.security-content h6 p {
  font-weight: 500;
  font-size: 10px;
  color: #989898;
  margin-top: 4px;
}
.security-content .form-check-input:checked {
  background-color: #0d6efd !important;
}
.security-content .form-check-input {
  width: 48px;
  height: 22px;
  background-color: #6c757d;
  border: 0;
}
.security-content .form-switch .form-check-label {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
.security-content span {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: #797979;
  display: inline-block;
  margin: 0 8px;
}
.security-content .form-switch {
  justify-content: space-between;
  padding: 8px 15px;
}
.privacy-input {
  background: none !important;
  color: #797979 !important;
  /* font-weight: 400; */
  /* font-size: 67px !important; */
  /* height: 35px; */
  /* line-height: 0px; */
  /* border: 0;    padding-bottom: 15px; */
  /* 
  outline: #fff solid 1px;
  border: none; */
}
/* .privacy-input::placeholder{
    font-size: 16px !important;
    padding-bottom: 30px !important;
    color: red !important;
} */


.privacy-edit-icon {
  cursor: pointer;
}
