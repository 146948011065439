
.mobile-screen .identification .create-payee-page .bank .form-select{
    background-color: #797979;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
}
.create-payee-page .bank .next-btn {
    margin-top: 43px;
}