.confirm-payee-information .payee-content h6 {
  background: #1f1e32;
  border: 1px solid #27253f;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0;
  padding: 10px 12px;
}
.confirm-payee-information .next-btn {
  margin-top: 16px;
}
.confirm-payee-information .payee-content h4 img {
  border-radius: initial;
}
.confirm-payee-information .payee-content h4 {
  font-weight: 600;
  font-size: 15px;
  color: #989898;
  padding: 12px 12px;
  margin-bottom: 0;
}
.confirm-payee-information .payee-content {
  margin: 0 -12px;
}
.confirm-payee-information img {
  border-radius: 8px;
}
