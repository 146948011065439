.link-mobile h3 {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
}
.link-mobile p {
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
}
.qr-code-box {
    background: #FFFFFF;
    border-radius: 20px;
    display: inline-block;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}