@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,700&family=Libre+Franklin:wght@100;200;300;400;500;600;700;800;900&family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,700&family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,700&display=swap");

.root {
  background: #18172a;
  position: relative;
  z-index: 9999;
}

.slider {
  background-image: url(../../public/icons/slider-bg.jpg);
  padding: 100px 0;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
}

.slider .slider-heading span {
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  background: linear-gradient(
    270deg,
    #99663b 27.69%,
    #c28134 53.45%,
    #f6d265 78.24%,
    #fde978 97.57%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 14px;
}

.navbar-collapse {
  justify-content: end;
  padding-right: 50px;
}

.slider .slider-heading h3 {
  font-weight: 500;
  font-size: 35px;
  line-height: 58px;
  margin-bottom: 0;
  color: #ffffff;
  margin-bottom: 30px;
}

.slider .slider-heading .launching-btn {
  background: #d9ae69;
  mix-blend-mode: normal;
  border-radius: 60px;
  display: inline-block;
  position: relative;
}

.slider .slider-heading .launching-btn a {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  align-items: center;
  text-decoration: none;
  padding: 10px 15px 10px 60px;
  color: #ffffff;
  display: block;
}

.slider .slider-heading .launching-btn::before {
  content: "";
  background-image: url(../../public/icons/arrow-right.png);
  height: 28px;
  width: 28px;
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 6%;
  transform: translateY(-50%);
}

.slider .slider-heading .launching-btn::after {
  content: "";
  background: #ca9344;
  filter: blur(2px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 5%;
  left: 2%;
}

.unicoin {
  padding: 100px 0 0;
}

.slider-img {
  position: relative;
  z-index: 9;
}

.slider-img::after {
  content: "";
  background-image: url(../../public/icons/slider-mobile-bg.png);
  height: 732px;
  width: 1433px;
  position: absolute;
  z-index: -9;
  top: -30%;
  right: -40%;
}

@media (max-width: 767px) {
  .slider {
    padding: 50px 0;
    background-position: top;
  }

  .difference-table-box {
    padding: 0px;
  }

  .difference .difference-table .border::after {
    width: 0px;
  }

  .ratings-star .bitcoin .unicoin-rating img {
    margin-bottom: 30px !important;
  }

  .difference .difference-table .border::before {
    width: 0px;
  }

  .slider .slider-heading span {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .slider .slider-heading h3 {
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 38px;
  }

  .slider .slider-heading .launching-btn a {
    font-size: 15px;
    line-height: 24px;
    padding: 8px 8px 8px 50px;
  }

  .slider .slider-heading .launching-btn::after {
    width: 40px;
    height: 40px;
  }

  .slider-img img {
    width: 100%;
  }

  .slider-img::after {
    height: 292px;
    width: 510px;
    top: -37%;
    right: 0;
    background-size: cover;
    opacity: 0.4;
  }

  .unicoin .unicoin-heading h3 {
    font-size: 50px;
  }

  .unicoin .unicoin-heading h3 span {
    font-size: 20px;
  }

  .unicoin .unicoin-heading h6 {
    font-size: 31px;
  }

  .unicoin .unicoin-heading img {
    width: 100%;
  }

  .unicoin {
    padding: 50px 0;
  }

  .unicoin-box .coine-img {
    width: 100%;
    top: -31%;
  }

  .unicoin-box img {
    top: -44%;
  }

  .unicoin-box {
    padding: 80px 20px 20px 20px;
    margin-bottom: 70px;
  }

  .unicon-title h4 {
    font-size: 12px;
    width: 50%;
  }

  .unicon-title p {
    font-size: 12px;
    display: flex;
  }

  .unicon-title img {
    width: 12%;
    height: 12%;
  }

  .unicon-title {
    border-top: 2px solid #d9ae69;
    padding: 20px 0;
  }

  .unicoin-store .unicoin-store-heading h3 {
    font-size: 22px;
    margin-top: 20%;
    margin-bottom: 40px;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 32px;
  }

  .unicoin-store .unicoin-store-text p {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .sov-img {
    width: 100%;
  }

  .physical-cash-img {
    width: 100%;
  }

  .electronic-cash-img {
    width: 100%;
  }

  .ratings {
    margin: 50px 0;
  }

  .ratings .ratings-box .ratings-box-icon h3 {
    font-size: 28px;
  }

  .ratings .ratings-box .ratings-box-icon {
    border-radius: 20px 20px 0px 0px;
  }

  .ratings .ratings-box .ratings-box-text h5 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .ratings .ratings-box .ratings-box-text {
    padding: 25px 25px;
  }

  .ratings-star .ratings-star-bg {
    padding: 4px;
  }

  .ratings .ratings-star {
    position: unset;
    top: unset;
    right: unset;
  }

  .ratings-star .ratings-star-bg .unicoin-rating img {
    margin-bottom: 20px;
  }

  .difference {
    padding: 50px 0;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    width: 150px;
    height: 150px;
    line-height: 89px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .difference
    .difference-table
    .difference-table-box
    .difference-table-icon
    h3 {
    font-size: 20px;
  }

  .difference .difference-table {
    padding: 30px 12px;
  }

  .difference .difference-table .border {
    border-right: unset !important;
    border-left: unset !important;
    padding: 0;
    border-bottom: 0 !important;
    border-top: 0 !important;
  }

  .tads-img {
    width: 100%;
  }

  .trophy-img {
    width: 100%;
  }

  .unicoin-store .trophy-heading {
    font-size: 17px;
    margin: 25px 0;
  }

  .unicoin-store .roadmap-heading p {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .roadmap-bg::after {
    display: none;
  }

  .roadmap-flow p {
    font-size: 18px;
    line-height: 28px;
    width: 100%;
    margin-right: 0;
    margin-top: 0;
  }

  .roadmap-flow h6::after {
    left: 50%;
    top: -11px;
    transform: translateX(-50%);
    width: 100%;
  }

  .roadmap-flow h6 {
    font-size: 22px;
    line-height: 0;
    width: unset;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-right: 0px;
    display: inline-block;
    margin-bottom: 70px;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .second h6 {
    margin-bottom: 70px;
  }

  .third img {
    margin-left: 0;
  }

  .footer {
    margin-top: 50px;
  }

  .difference .difference-table .difference-table-box h4 {
    font-size: 17px;
  }

  .difference .difference-table .difference-table-box p {
    font-size: 14px;
    line-height: 22px;
  }

  .footer .footer-text h5 {
    font-size: 18px;
  }

  .footer .footer-text a {
    font-size: 18px;
    margin: 20px 0 40px;
  }

  .footer .footer-text p {
    font-size: 18px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .slider-img::after {
    height: 512px;
    width: 877px;
    top: -34%;
    right: 0;
  }

  .slider .slider-heading .launching-btn::after {
    width: 35px;
    height: 35px;
  }

  .unicoin-box img {
    top: -65%;
  }

  .unicon-title h4 {
    font-size: 16px;
  }

  .unicon-title p {
    font-size: 14px;
    display: flex;
  }

  .unicon-title img {
    width: unset;
    height: unset;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    left: 0%;
    width: 100%;
    margin-top: -3%;
  }

  .difference .difference-table {
    padding: 30px 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider .slider-heading span {
    font-size: 18px;
  }

  .difference-table-box {
    padding: 0px;
  }

  .difference .difference-table .border::after {
    right: -3%;
  }

  .difference .difference-table .border::before {
    left: -3%;
  }

  .slider .slider-heading h3 {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .slider .slider-heading .launching-btn a {
    font-size: 14px;
    padding: 8px 8px 8px 50px;
  }

  .slider .slider-heading .launching-btn::after {
    width: 42px;
    height: 42px;
    top: 8%;
  }

  .slider-img img {
    width: 100%;
  }

  .slider-img::after {
    width: 577px;
    top: -28%;
    right: -13%;
    height: 352px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .slider {
    padding: 70px 0;
    background-position: top;
  }

  .unicoin .unicoin-heading h3 {
    font-size: 100px;
  }

  .unicoin .unicoin-heading h6 {
    font-size: 45px;
  }

  .unicoin .unicoin-heading img {
    width: 100%;
  }

  .unicoin {
    padding: 70px 0;
  }

  .unicoin-box {
    padding: 70px 20px 20px 20px;
    margin-bottom: 70px;
  }

  .unicoin-box img {
    top: -48%;
  }

  .unicon-title {
    padding: 24px 0;
  }

  .unicon-title h4 {
    font-size: 14px;
    width: 50%;
  }

  .unicon-title p {
    font-size: 14px;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 60px;
    width: 100%;
    margin-top: -6%;
    left: 0;
  }

  .unicoin-store .unicoin-store-heading h3 {
    font-size: 42px;
    margin-bottom: 40px;
    margin-top: 15%;
  }

  .unicoin-store .unicoin-store-text p {
    /* font-family: 'Quicksand'; */
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 10px;
    width: 100%;
  }

  .sov-img {
    width: 100%;
  }

  .physical-cash-img {
    width: 100%;
  }

  .electronic-cash-img {
    width: 100%;
  }

  .ratings .ratings-box .ratings-box-icon h3 {
    font-size: 26px;
  }

  .ratings .ratings-box .ratings-box-icon {
    padding: 38px 25px;
  }

  .ratings .ratings-box .ratings-box-text h5 {
    font-size: 16px;
    line-height: 22px;
  }

  .ratings .ratings-box .ratings-box-text {
    padding: 41px 0;
  }

  .ratings-star .ratings-star-bg {
    padding: 15px;
  }

  .star-icon img {
    width: 15%;
  }

  .ratings .ratings-star {
    top: -26%;
    right: -7%;
  }

  .retings-star-icon {
    margin-bottom: 109px;
  }

  .ratings-star .ratings-star-bg .unicoin-rating img {
    margin-bottom: 50px;
  }

  .retings-star-icon-1 {
    margin-bottom: 126px;
  }

  .ratings-star .bitcoin .unicoin-rating img {
    margin-bottom: 60px;
  }

  .ratings .ratings-star .text-center {
    width: 40%;
  }

  .ratings {
    margin: 200px 0 70px;
  }

  .difference
    .difference-table
    .difference-table-box
    .difference-table-icon
    img {
    width: 30%;
    margin-top: 20px;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    width: 150px;
    height: 150px;
    line-height: 85px;
    margin-top: -200px;
  }

  .difference
    .difference-table
    .difference-table-box
    .difference-table-icon
    h3 {
    font-size: 16px;
  }

  .difference .difference-table .border {
    padding: 0px 14px;
  }

  .difference .difference-table .difference-table-box h4 {
    font-size: 15px;
  }

  .difference .difference-table .difference-table-box p {
    font-size: 12px;
    line-height: 20px;
  }

  .unicoin-store .roadmap-heading p {
    font-size: 22px;
    margin-bottom: 40px;
  }

  .roadmap-flow p {
    font-size: 18px;
    line-height: 28px;
    width: 100%;
    margin-right: 0px;
    margin-top: 0px;
  }

  .roadmap-flow h6 {
    font-size: 22px;
    line-height: 28px;
    width: 90%;
    margin-top: 0px;
    margin-right: 0px;
  }

  .roadmap-flow h6::after {
    width: 150%;
    left: -28%;
    top: 26px;
    background-size: contain;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .roadmap-bg::after {
    width: 80px;
    top: 38%;
    background-size: contain;
    height: 597px;
  }

  .third img {
    margin-left: 0px;
  }

  .unicoin-store .trophy-heading {
    font-size: 35px;
    margin: 40px 0;
  }

  .footer .footer-text h5 {
    font-size: 18px;
  }

  .footer .footer-text a {
    font-size: 18px;
    margin: 15px 0 30px;
  }

  .footer .footer-text p {
    font-size: 18px;
  }

  .footer {
    margin-top: 70px;
    padding: 30px 0 35px;
  }
  .img-size {
    width: 35px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .img-size {
    width: 35px !important;
  }
  .slider .slider-heading span {
    font-size: 24px;
  }

  .ratings-star .ratings-star-bg .unicoin-rating img {
    margin-bottom: 40px;
  }

  .ratings-star .bitcoin .unicoin-rating img {
    margin-bottom: 50px;
  }

  .slider .slider-heading h3 {
    font-size: 35px;
    line-height: 44px;
    margin-bottom: 25px;
  }

  .slider-img img {
    width: 100%;
  }

  .slider-img::after {
    height: 532px;
    top: -25%;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 683px;
  }

  .slider {
    background-attachment: fixed;
    background-size: cover;
  }

  .unicoin .unicoin-heading h3 {
    font-size: 100px;
  }

  .unicoin .unicoin-heading img {
    width: 100%;
  }

  .unicoin .unicoin-heading h6 {
    font-size: 40px;
  }

  .unicoin {
    padding: 100px 0 0;
  }

  .unicon-title h4 {
    font-size: 22px;
    width: 45%;
  }

  .unicon-title p {
    font-size: 16px;
  }

  .unicon-title {
    padding: 32px 0;
  }

  .unicoin-box {
    padding: 80px 20px 20px 20px;
    margin-bottom: 70px;
  }

  .unicoin-box p {
    font-size: 16px;
    line-height: 22px;
  }

  .unicoin-box img {
    top: -55%;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 106px;
  }

  .unicoin-store .unicoin-store-heading h3 {
    margin-top: 18%;
    margin-bottom: 70px;
  }

  .sov-img {
    width: 100%;
  }

  .physical-cash-img {
    width: 100%;
  }

  .electronic-cash-img {
    width: 100%;
  }

  .roadmap-flow p {
    font-size: 22px;
    width: 75%;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .roadmap-flow h6 {
    font-size: 22px;
    width: 64%;
  }

  .roadmap-bg::after {
    width: 109px;
    height: 862px;
    top: 37%;
    background-size: cover;
  }

  .ratings .ratings-box .ratings-box-text h5 {
    font-size: 16px;
  }

  .ratings .ratings-star {
    top: -27%;
    right: -3%;
  }

  .ratings-star .ratings-star-bg {
    padding: 14px;
  }

  .difference .difference-table .difference-table-box h4 {
    font-size: 18px;
  }

  .difference .difference-table .difference-table-box p {
    font-size: 14px;
    line-height: 22px;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    margin-top: -214px;
  }

  .difference-table-box {
    padding: 0;
  }

  .difference .difference-table .border::before {
    left: -5%;
  }

  .difference .difference-table .border::after {
    right: -4%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .slider .slider-heading span {
    font-size: 29px;
  }

  .difference-table-box {
    padding: 0px;
  }

  .difference .difference-table .border::before {
    left: -4%;
  }

  .difference .difference-table .border::after {
    right: -3.5%;
  }

  .slider .slider-heading h3 {
    font-size: 38px;
    line-height: 62px;
  }

  .slider-img img {
    width: 100%;
  }

  .slider-img::after {
    height: 452px;
    width: 833px;
    background-size: contain;
    right: 0;
    top: -26%;
  }

  .electronic-cash-img {
    width: 100%;
  }

  .slider {
    background-size: cover;
  }

  .ratings .ratings-star {
    right: -2%;
    top: -36%;
  }

  .unicoin .unicoin-heading h3 {
    font-size: 160px;
  }

  .unicoin .unicoin-heading h6 {
    font-size: 54px;
  }

  .unicon-title h4 {
    font-size: 22px;
    width: 38%;
  }

  .unicon-title p {
    font-size: 22px;
  }

  .unicoin-box {
    padding: 80px 20px 20px 20px;
    margin-bottom: 110px;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 130px;
  }

  .difference .difference-table .difference-table-box h4 {
    font-size: 23px;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    margin-top: -218px;
  }

  .roadmap-flow p {
    width: 62%;
  }

  .roadmap-flow h6 {
    width: 57%;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .roadmap-bg::after {
    width: 135px;
  }
}

@media (min-width: 1700px) and (max-width: 1899px) {
  .slider-img::after {
    width: 1338px;
    right: -24%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .slider-img::after {
    height: 446px !important;
    width: 901px !important;
    background-size: contain !important;
    top: -19% !important;
    right: 0;
  }

  .slider .slider-heading h3 {
    width: 100%;
  }
}

@media (min-width: 1600px) and (max-width: 1699px) {
  .slider-img::after {
    height: 562px;
    width: 1329px;
    top: -40%;
    right: -14%;
  }

  .slider .slider-heading h3 {
    width: 100%;
  }
}

@media (min-width: 1700px) and (max-width: 1899px) {
  .slider-img::after {
    height: 562px;
    width: 1289px;
    top: -32%;
    right: -14%;
  }

  .slider .slider-heading h3 {
    width: 100%;
  }
}

@media (min-width: 1300px) and (max-width: 1699px) {
  .slider-img img {
    width: 100%;
  }

  .difference-table-box {
    padding: 0px;
  }

  .difference .difference-table .border::before {
    left: -4.5%;
  }

  .difference .difference-table .border::after {
    right: -3%;
  }

  .difference .difference-table .difference-table-box .difference-table-icon {
    margin-top: -217px;
  }

  .slider {
    background-size: cover;
  }

  .unicoin-box {
    padding: 80px 20px 20px 20px;
  }

  .unicoin-store .unicoin-store-heading h3::before {
    font-size: 136px;
  }

  .roadmap-flow p {
    width: 62%;
  }

  .roadmap-flow h6 {
    width: 52%;
  }

  .roadmap-flow img {
    width: 100%;
  }

  .roadmap-bg::after {
    width: 135px;
  }

  .header .navbar {
    padding: 18px 20px 18px 75px !important;
  }

  .header .navbar .navbar-brand {
    margin-right: 60px !important;
  }
}
.img-size {
  width: 56px;
}
@media (max-width: 767px) {
  .img-size {
    width: 25px;
  }
  .span-width {
    width: unset;
  }
  .image-class {
    width: 25px;
  }
  .content h5 {
    margin-bottom: 0;
  }
  .home .content .text-style span {
    display: block;
  }
}

.text-blue {
  color: lightblue !important;
}

/* Chart styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.active-period {
  border-radius: 22px;
  border: solid 1px #dcdcdc;
  margin: 5px;
  padding: 10px 21px 8px 21px;
  background-color: #7f8385;
  color: white;
}

.inactives {
  border-radius: 22px;
  border: solid 1px #dcdcdc;
  margin: 5px;
  padding: 10px 21px 8px 21px;
}

/* .toolbar{
  margin-left: 1370px;
} */
.select-box {
  width: 270px;
  height: 37px;
  background-color: #f5f5f5;
  border: solid 1px #dcdcdc;

  /* / background-color: rgb(238, 235, 235);  */
}

.input-box {
  width: 264px;
  height: 40px;
  border: solid 1px #dcdcdc;
}

.main-d {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-start: 10px;
}

.main-select {
  margin: 0px 17px;
}

.apexcharts-xaxistooltip {
  background: transparent;
  border: none;
  color: #2b63dc;
  margin-top: 4px;
  padding-bottom: 0;
  font-weight: bold;
}

.containe {
  color: #ffffff !important;
  padding-inline: 20px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-start: 10px;
}

.currancy-main {
  display: flex;
  margin-inline-start: 75px;
}

.chart-count {
  display: flex;
}

.currancy-main button {
  margin-inline-end: 10px;
  padding: 5px 20px;
}

@media only screen and (max-width: 885px) {
  .toolbar {
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  .chart-count {
    display: block;
  }

  .currancy-main {
    display: inline-block;
    margin-block-end: 15px;
    margin-inline-start: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 615px) {
  .main-d {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .main-select {
    margin: 17px 0;
  }
}

@media only screen and (max-width: 400px) {
  .toolbar {
    margin: 10px;
  }

  .active-period {
    margin: 5px;
    padding: 8px 16px 6px 16px;
  }

  .inactives {
    margin: 5px;
    padding: 8px 16px 6px 16px;
  }

  .input-box {
    width: 170px;
    height: 37px;
  }

  .select-box {
    width: 176px;
    height: 37px;
  }
}

.apexcharts-xaxis-title {
  margin-top: 10px !important;
}

.activekey {
  background-color: #7f8385;
  color: white;
  border: solid 1px #dcdcdc;
}
.inactiveskey {
  border: solid 1px #dcdcdc;
}
/* Chart styles */
.tab-content p {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}