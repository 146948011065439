.email-address .phone-verify-content form {
  margin-top: 100px;
}

.email-address .phone-verify-content .next-btn {
  /* margin-top: 484px !important; */
}

.email-address .phone-verify-content .form-control-2 {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #fff;
  color: #797979;
  font-size: 100px;
}
