.activity-content .payments-list {
  background: #1f1e32;
  border: 1px solid #27253f;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 10px 15px;
  cursor: pointer;
}

.payments-list:hover {
  border: 1px solid #ababab;
}

.activity-content .payments-list h6.green {
  color: #58d86c;
}
.activity-content .payments-list h6.red {
  color: #ca1212;
}
.activity-content .payments-list h6 {
  font-weight: 600;
  font-size: 15px;
}
.activity-content .payments-list p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  color: #797979;
}
.activity-content .input-group-text {
  background: #797979;
  border-radius: 8px 0 0 8px;
  font-weight: 400;
  border-right: 0;
  font-size: 14px;
  color: #ffffff;
}
.activity-content .border-left-0 {
  border-left: 0;
  padding-left: 0;
}
.activity-content .payments-list h3 {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 4px;
}
