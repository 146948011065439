.email-content .modal-box .modal-list h5 {
  font-weight: 600;
  font-size: 13px;
  color: #000000;
  margin-bottom: 0;
  overflow-wrap: break-word;
  text-align: center;
}
.email-content .modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#CashIDinput {
  padding-right: 90px !important;
}
