.payees-content h6 {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 16px;
}
.payees-content .payees-list p {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.payees-content .payees-list img.payee_image,
img.payee_image {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.payees-content .payees-list {
  display: flex;
  align-items: center;
  background: #1f1e32;
  border: 1px solid #27253f;
  border-radius: 8px;
  padding: 10px 15px;
}
.payees-content .input-group .form-control {
  border-left: 0;
  padding-left: 0;
}
.payees-content .plus-icon {
  background: #797979;
  border-radius: 8px;
  font-size: 20px;
  color: #ffffff;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payees-content .input-group .input-group-text {
  background: #797979;
  color: #ffffff;
  font-weight: 900;
  font-size: 16px;
  border-right: 0;
}

payees_table_container {
    /* display: flex; */
    /* align-items: center; */
    background: #1f1e32;
    border: 1px solid #27253f;
    border-radius: 8px;
    padding: 10px 15px;
}
