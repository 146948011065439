.email-address {
  padding: 50px 0;
}

.email-address .email-content p {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
}

    .email-address .email-content .form-control::placeholder{
        color: #fff;
    }
    .email-address .email-content .next-btn {
        background: #3361A3;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        width: 100%;
        margin-top: 539px;
    }
    .email-address .email-content .form-control {
        background: #797979;
        border-radius: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        margin-top: 50px;
    }
    .email-address .email-content h3 {
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
    }
