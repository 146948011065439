.milestones .header .arrow-bg {
    width: 46px;
}
.milestones .milestones-list p {
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
}
.milestones .milestones-list .milestones-box h4.red {
    background: #F08585;
    border-radius: 4px;
    color: #CA1212;
}
.milestones .milestones-list .milestones-box h4.green {
    color: #176C25;
    background: #58D86C;
    border-radius: 4px;
}
.milestones .milestones-list .milestones-box h4 {
    font-weight: 600;
    font-size: 12px;
    margin-bottom:0;
    padding: 4px 6px;
}
.milestones .milestones-list .milestones-box h5 {
    font-weight: 600;
    font-size: 14px;
    color: #58D86C;
}
.milestones .milestones-list .milestones-box p {
    font-weight: 500;
    font-size: 12px;
    color: #797979;
    margin-bottom:0;
}
.milestones .milestones-list .milestones-box h6 {
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}
.milestones .milestones-list .milestones-box {
    background: #1F1E32;
    border: 1px solid #27253F;
    border-radius: 8px;
    padding: 8px 15px;
    display: flex;
    align-items:center;
    justify-content:space-between;
}