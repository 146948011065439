.title-heading {
  font-size: 24px;
}

.texts p {
  font-size: 12px;
}

.texts ol li {
  font-size: 12px;
}
