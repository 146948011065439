.exchange-rates p:hover {
  background: #3361a3;
  color: #ffffff !important;
}
.exchange-rates .tabs p {
  padding: 8px 31px !important;
}
.exchange-rates .tabs {
  width: 290px;
}
.exchange-rates p {
  transition: all 0.5s;
}
.exchange-rates .plus-icon i {
  font-size: 34px;
  color: #fff;
}
.exchange-rates .plus-icon {
  background: #797979;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  text-decoration: none;
}
.exchange-rates a {
  text-decoration: none;
}
.table-bg .table tbody td .table-icon {
  width: 20px;
  height: 20px;
  background: #797979;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-bg .table tbody td {
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  padding: 3px;
  vertical-align: middle;
}
.table-bg .table {
  margin-bottom: 0;
}
.table-bg .table thead {
  border-bottom: 1px solid #27253f;
}
.table-bg .table thead th {
  font-weight: 600;
  font-size: 11px;
  color: #989898;
}
.table-bg {
  background: #1f1e32;
  border: 1px solid #27253f;
  border-radius: 8px;
  padding: 10px;
}

.exchange-rates button.next-btn {
  background-color: transparent !important;
  border: 2px solid #3361a3;
  color: #3361a3 !important;
  margin: 10px !important;
}

.table-bg .table-height {
  height: 570px;
  overflow: hidden;
  overflow-y: scroll;
}
.table-bg .next-btn {
  padding: 8px 30px;
}
