.next-btn-border {
  background-color: transparent !important;
  border: 2px solid #3361a3;
  color: #3361a3 !important;
}

.table-bg .crypto-img {
  width: 16px;
  height: 16px;
}
.table-bg {
  background: #1f1e32;
  border: 1px solid #27253f;
  border-radius: 8px;
  padding: 10px;
}
.table-content .action-btn {
  background: #797979;
  border-radius: 2px;
  border: 1px solid #c2c9d1;
  padding: 4px 5px;
  color: #fff;
}
.table-content .email-link {
  text-decoration: none;
  color: #72b5db;
}
.table-content .table thead th,
.table-content .table tbody td {
  font-size: 12px !important;
}

.add .add-btn {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #797979;
  background-color: #fff;
  padding: 0px 8px;
  border-radius: 8px;
  text-decoration: none;
}
.add {
  width: 100%;
  position: relative;
}

.create-payee-page .disabled .light {
  display: none;
}
.create-payee-page .disabled .dark {
  display: block !important;
}
.create-payee-page .disabled:hover .dark,
.create-payee-page .disabled.active .dark {
  display: block !important;
}

.disabled h5 {
  color: #c7c7c7 !important;
}
.disabled {
  background-color: #f3f3f37a !important;
  border: 0 !important;
  pointer-events: none;
  opacity: 0.7;
}
