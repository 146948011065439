.activity-content .tabs a{
    text-decoration: none;
}
.activity-content .tabs p{
    transition: all 0.5s;
}
.activity-content .tabs p.active,
.activity-content .tabs p:hover {
    background: #3361A3;
    color: #FFFFFF;
}
.activity-content .escrows-list h4 {
    font-weight: 600;
    font-size: 15px;
    color: #58D86C;
    margin-bottom: 0;
}
.activity-content .escrows-list p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    color: #797979;
}
.activity-content .escrows-list h3 {
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 4px;
}
.activity-content .escrows-list .bg-icons {
    background: #797979;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content:center;
}
.activity-content .escrows-list {
    padding: 10px 6px 10px 15px;
    background: #1F1E32;
    border: 1px solid #27253F;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}