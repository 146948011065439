.success-box p {
    font-weight: 400;
    font-size: 14px;
    color: #797979;
}
.success-box .line {
    border: 2px dashed #000000;
}
.success-box .round {
    width: 24px;
    height: 24px;
    background: #18172A;
    border-radius: 100%;
}
.success-box .round-line{
    margin-left:-32px;
    margin-right: -32px;
    margin-bottom: 20px;
}
.success-box .summary-list .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
.success-box h1 {
    margin-bottom: 4px;
}