.crypto-select .select {
  background: #797979;
  border-radius: 8px 0 0 8px;
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-right: 0;
  padding: 4.7px 10px;
}
.crypto-select {
  position: relative;
  z-index: 10000;
}
.crypto-select h6 {
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  margin-bottom: 0;
  padding: 0 6px;
}

.crypto-select .crypto-dropdown {
  position: absolute;
  left: 0;
  top: 35px;
  background-color: #797979;
  padding: 10px;
  height: 130px;
  /* height: 200px; */
  /* height: 800px; */
  overflow: hidden;
  overflow-y: scroll;
}

.crypto-select .crypto-dropdown span {
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  display: inline-block;
}

#walletInput {
  padding-right: 70px;
}

.add_umu_link {
  color: #58d86c;
  text-decoration: none;
}

.add_umu_link:hover {
  color: #58d86c;
}
