.escrow-information .summary-list img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.next-btn-last {
  margin-bottom: 100px;
}

.bg_gray {
  background: #ededed;
}
