.create-payee-page p {
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    margin-top: 30px;
}
.create-payee-page .payee-box .dark{
    display: none;
}
.create-payee-page .payee-box .light{
    display: block;
}
.create-payee-page .payee-box:hover .dark,
.create-payee-page .payee-box.active .dark{
    display: block;
}
.create-payee-page .payee-box:hover .light,
.create-payee-page .payee-box.active .light {
    display: none;
}
.create-payee-page .payee-box  img {
    margin: auto;
    margin-bottom: 5px;
}
.create-payee-page .payee-box:hover,
.create-payee-page .payee-box.active {
    background: #58D86C;
    border: 2px solid #27253F;
    color: #000000;
}
.create-payee-page .payee-box:hover h5,
.create-payee-page .payee-box.active h5{
    color: #000000;
}
.create-payee-page .payee-box h5 {
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
}
.create-payee-page .payee-box {
    width: 100%;
    height: 64px;
    text-align: center;
    padding:  10px 0;
    border: 2px solid #A0A0A0;
    border-radius: 8px;
    display: inline-block;
    text-decoration: none;
    background-color: transparent;
}
.create-payee-page .next-btn{
    width: 100%;
    margin-top: 295px;
}
