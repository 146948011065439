.top-heading {
  font-size: 31px;
}

.dw-btn {
  background-color: #3361a3;
}

.dw-btn:hover {
  background-color: #3c5881;
}

@media (min-width: 768px) and (max-width: 991px) {
  .top-heading {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .top-heading {
    font-size: 22px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .top-heading {
    font-size: 27px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .top-heading {
    font-size: 31px;
  }
}
