.email-address .email-content .tabs {
  background: #1f1e32;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  margin-top: 80px;
}
.email-address .email-content .tabs p.active {
  background: #3361a3;
  color: #ffffff;
}
.email-address .email-content .tabs p {
  padding: 8px 46px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #989898;
  margin-bottom: 0;
  cursor: pointer;
}
.email-address .phone-content .form-control {
  margin-top: 0 !important;
}
.email-address .email-content .form-control-1::placeholder {
  color: #ffffff !important;
}
.email-address .email-content .form-control-1 {
  font-weight: 600;
  background-color: transparent;
  border: 0;
  font-size: 18px;
  color: #ffffff !important;
}
.email-address .phone-content .dropdown-option span {
  font-size: 13px;
}
/* .email-address .phone-content .next-btn {
  margin-top: 150px !important;
} */

.phone-bg input {
  width: 100%;
}
.phone-bg .text-light {
  background: #4d4b70;
  border-radius: 8px;
}
.phone-bg {
  background: #2e2c50;
  border-radius: 8px;
  padding: 8px;
}

.rm-arrow {
  border: 1px solid white !important;
  border-radius: 5px !important;
  outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
.rm-arrow::-webkit-outer-spin-button,
.rm-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.rm-arrow[type="number"] {
  -moz-appearance: textfield;
}
