.purchases-list thead tr th {
    /*font-size: 12px;*/
    white-space: nowrap;
    /*color: #fff;
    padding: 4px;*/
}

.purchases-list .table tbody td {
    font-size: 11px !important;
    /*color: #fff;*/
    white-space: nowrap;
    /*padding: 4px;*/
    vertical-align: middle;
}
.payees-content p{
    font-size: 12px;
    color: #fff;
}
.purchases-add .purchases-add-box p{
    font-size: 12px;
    color: #fff;
    margin-bottom:0;
}
.purchases-add .purchases-add-box h3{
    margin: 10px 0 4px;
    font-size: 12px;
    color: #fff;
}
.purchases-add .purchases-add-box:hover {
    background-color: #0074ff;
}
.purchases-add .copy h3{
    font-size: 18px;
    color: #fff;
}
.purchases-add .copy p{
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
}
.purchases-add .continue{
    background-color: #0074ff;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    width: 100%;
    text-align: center;
    padding: 6px 0;
    display: block;
    border-radius: 5px;
    font-weight: 600;
}
.purchases-add .copy {
    border: 2px solid #0f325c;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}
.purchases-add .purchases-add-box {
    border: 2px solid #0f325c;
    border-radius: 10px;
    text-decoration: none;
    display:flex;
    align-items: center;
    padding: 10px 0;
    justify-content: center;
    transition: all 0.5s;
}