.splash .splash-content h3 {
  font-family: "Lato";
  font-weight: 600;
  font-size: 39px;
  line-height: 44px;
  letter-spacing: 0.08em;
  color: #ffffff;
}

.splash .splash-content .login-btn .icon-login {
  font-size: 30px;
  vertical-align: middle;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}
.splash .splash-content .login-btn {
  position: relative;
  width: 100%;
}
.splash .splash-content .next-btn {
  background: #3361a3;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-decoration: none;
  padding: 17px 10px 15px;
  display: inline-block;
  margin-top: 98px;
}
.splash .splash-content p {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  margin-top: 50px;
  color: #9c9a9a;
}

.forgot-password {
  background: transparent;
  border: 0;
  color: #3361a3;
}

.btn-blue {
  background: #3361a3;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 10px;
  display: inline-block;
}
