.error-conten{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    text-align:center;
    width: 250px;
}
    .error-conten p {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
    }
