.product-upload span {
    font-size: 13px !important;
    color: #ffffff !important;
}
.product-upload i {
    color: #ffffff !important;
}

.total-list h3 {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 0;
}

.total-list h6 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
}
.total-list hr {
    border: 2px solid #FFFFFF;
    opacity:1;
    margin:0;
}