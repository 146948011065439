.summary-box {
    background: #FFFFFF;
    border-radius: 8px;
    margin-top:70px;
    position:relative;
    padding: 40px 20px 20px;
}
    .summary-box .summary-list h5 span {
        font-weight: 600;
        font-size: 20px;
        color: #797979;
    }
    .summary-box .summary-list h5 {
        font-weight: 600;
        font-size: 16px;
        color: #18172A;
        margin-bottom:0;
    }
    .summary-box .summary-list p {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.3);
    }
    .summary-box h1 {
        font-family: 'Lato';
        font-weight: 600;
        font-size: 36px;
        letter-spacing: 0.08em;
        color: #18172A;
    }
    .summary-box .summary-box-logo img {
        position: absolute;
        top:-40px;
        left:50%;
        transform:translateX(-50%);
    }