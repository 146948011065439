@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

.apexcharts-tooltip {
  color: #000;
}

.logo_loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(160, 160, 160, 0.2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
}

.logo_loader_img {
  width: 64px !important;
  height: 64px !important;
}

.mobile-screen > div {
  min-width: 375px;
  width: 375px;
  margin: auto;
}
.padding-bottom {
  padding-bottom: 0px !important;
}
.mobile-screen {
  background: #18172a;
  min-width: 100%;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 110px;
}

.mobile-screen .header-bottom {
  position: fixed;
  bottom: 0;
  min-width: 375px;
  width: 375px;
  left: 50%;
  transform: translateX(-50%);
}

.main-logo {
  width: 54px;
  height: 54px;
}

.mobile-screen .header-bottom .header-bottom-bg .center-part {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mobile-screen .header-bottom .header-bottom-bg h6 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
  color: #989898;
}

.mobile-screen .header-bottom .header-bottom-bg .logo-bg {
  position: absolute;
  top: -27px;
  left: 34%;
}

.mobile-screen .header-bottom .header-bottom-bg {
  padding: 10px 0;
  background: #1f1e32;
  border: 1px solid #27253f;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 999;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff82;
}

.last {
  margin-bottom: 100px;
}

.modal-box .icons-fixed {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -82px;
}

.modal-box h2 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #000000;
}

.modal-box .modal-list h6 {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0;
  color: #797979;
}

.modal-box .modal-list h5 {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  margin-bottom: 0;
}

.modal-box .modal-list {
  background: #e4e4e4;
  border-radius: 4px;
  padding: 8px;
}

.modal-box .modal-btn.fill {
  background: #3376da;
  color: #ffffff;
}

.modal-box .modal-btn {
  border: 2px solid #3376da;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3376da;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 24px;
}

.modal-box {
  width: 300px;
  height: 226px;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  margin: auto;
  padding: 15px 16px 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-address {
  margin-bottom: 100px;
}
.modal.fade .modal-dialog {
  transition: unset;
  transform: unset;
}

.mobile-screen .header-bottom .header-bottom-bg .active .light {
  display: inline-block;
}

.mobile-screen .header-bottom .header-bottom-bg .active .dark {
  display: none;
}

.mobile-screen .header-bottom .header-bottom-bg .light {
  display: none;
}

.mobile-screen .header-bottom .header-bottom-bg .dark {
  display: inline-block;
}

.transaction-page .money-transaction-icon h3 i {
  color: #fff;
}
.transaction-page .money-transaction-icon h3 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
}
.transaction-page .money-transaction-text p {
  margin-bottom: 0;
  padding: 8px 10px;
  font-size: 14px;
  color: #949494;
  font-weight: 600;
  padding-bottom: 20px;
}
.transaction-page .money-transaction-icon {
  background: #1f1e32;
  border: 1px solid #27253f;
  padding: 8px 10px;
}
.transaction-page .money-heading h3 {
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
  margin-bottom: 0;
}
.transaction-page .money-heading p {
  margin-bottom: 0;
  padding-left: 10px;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #3376da;
}
.popup-model {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home {
  width: 100% !important;
  min-width: 100% !important;
}
.home .menu-list {
  align-items: center;
  color: #000;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.03em;
  padding-left: 24px !important;
  padding-right: 24px !important;
  position: relative;
  text-decoration: none;
  font-family: Oxygen;
}
.home .content h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
}

/* .data{
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  overflow-x: auto;
} */
.home .logo {
  margin: auto;
  width: 15%;
  margin-top: -44px;
  margin-bottom: -50px;
}
.evan {
  background-color: #262440;
}
.border-1 {
  border: 1px solid #fff;
}
footer {
  background: #002843;
  padding: 50px 0;
}
footer .footer-logo img {
  margin-bottom: 20px;
}
footer .footer-logo p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
footer .social-media-links h3,
footer .legal-information h3 {
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-top: 30px;
}
footer .social-media-links ul li,
footer .legal-information ul li {
  list-style: none;
}
footer .legal-information ul li a {
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 12px;
  position: relative;
}
footer .legal-information ul li a::after {
  content: "";
  width: 8px;
  height: 8px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -25px;
  transition: all 0.5s;
}
footer .legal-information ul li a:hover::after {
  width: 12px;
  height: 2px;
  transform: rotate(180deg);
  left: -30px;
}
footer .social-media-links ul {
  display: flex;
  align-items: center;
  padding: 0;
}
footer .social-media-links ul li {
  width: 40px;
  height: 40px;
  transition: all 0.5s;
  text-align: center;
  line-height: 40px;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
}
footer .social-media-links ul li a {
  text-decoration: none;
}
footer .social-media-links ul li a i {
  color: #0470b9;
  font-size: 20px;
  transition: all 0.5s;
}
footer .social-media-links ul li:hover {
  background: unset;
}

.image-class {
  width: 32px;
}
.content h5 {
  font-size: 15px;
}
.span-width {
  width: 100px;
}
@media (max-width: 767px) {
  .content h5 {
    font-size: 10px;
  }

  .home .content .text-style span {
    font-size: 10px;
  }
  .home .content h3 {
    font-size: 20px;
  }
  .image-class {
    width: 12px;
  }
  .home .menu-list {
    font-size: 10px;
    padding-left: 9px !important;
    padding-right: 9px !important;
  }
  .home .logo {
    width: 25%;
    margin-top: -30px;
    margin-bottom: -30px;
  }
}
@media (max-width: 575px) {
  .home .logo {
    width: 25%;
    margin-top: -16px;
    margin-bottom: -14px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .content h5 {
    font-size: 14px;
  }

  .home .content .text-style span {
    font-size: 12px;
  }
  .home .content h3 {
    font-size: 30px;
  }
  .image-class {
    width: 22px;
  }

  .home .logo {
    width: 15%;
    margin-top: -23px;
    margin-bottom: -23px;
  }
}
@media (min-width: 992px) and (max-width: 1499px) {
  .home .logo {
    width: 13%;
    margin-top: -23px;
    margin-bottom: -23px;
  }
}
.not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.not-found h3 {
  font-size: 50px;
  font-weight: 600;
}
.not-found p {
  font-size: 20px;
}

/* Escrow Page - Start */
.escrow_menu_button {
  /* back */
  padding: 0;
  margin: 0;
  border: none;
  font-size: 15px;
  /* line-height: 0; */
  /* border-radius: 2px; */
  background-color: transparent;
}

.escrow_display_name {
  font-size: 12px;
  color: #797979;
}
/* Escrow Page - End */
.modal-dialog {
  pointer-events: unset;
}
