.enter-amount {
  margin-top: 50px;
}
.identification .enter-amount .next-btn {
  margin-top: 35px;
}
.enter-amount .form-control {
  font-family: "Lato";
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 0.02em;
  color: #ffffff;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.enter-amount h1 {
  font-family: "Lato";
  font-weight: 600;
  font-size: 54px;
  color: #797979;
}
.enter-amount .enterp-amount-content {
  display: flex;
  align-items: center;
  background: #1f1e32;
  border: 1px solid #27253f;
  border-radius: 8px;
  padding: 6px 10px;
  margin-top: 30px;
}
.enter-amount .enterp-amount-content .form-floating label {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  opacity: 1;
}
.enter-amount .enterp-amount-content .form-floating {
  width: 84%;
}
.enter-amount .enterp-amount-content .form-floating .form-select {
  font-weight: 400;
  font-size: 16px;
  color: #797979;
  padding-top: 33px;
  width: 100%;
}
.enter-amount .enterp-amount-content .form-floating .form-select {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}
.enter-amount .enterp-amount-content .box-logo img {
  width: 100%;
}
.enter-amount .enterp-amount-content .box-logo {
  /* background: #797979; */
  border-radius: 8px;
  height: 50px;
  width: 50px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-option {
  background-color: #1f1d36;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  height: 200px;
  /* border: 1px solid #3c3a3a; */
  /* border-radius: 8px; */
  overflow-y: scroll;
}
.font-size {
  font-size: 13px;
  font-weight: 500;
}
