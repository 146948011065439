.wallet-page .profile-name img.user-profile {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}
.wallet-page .profile-list h6 {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0;
}
.wallet-page .profile-list {
  text-decoration: none;
  background: #1f1e32;
  border: 1px solid #27253f;
  border-radius: 8px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
}
.wallet-page .profile-name h3.currency {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  margin-top: 10px;
}

.local-currency {
  font-weight: 900;
  font-size: 16px;
  color: #ffffff;
}

.wallet-page .profile-list-currency {
  text-decoration: none;
  background: #1f1e32;
  /* border: 1px solid #27253f; */
  border-radius: 8px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
}
.modal-backdrop.fade.show {
  display: none;
}

.wallet-page .profile-list-currency p {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0;
}
.wallet-head {
  margin-right: -40px;
}

.no_arrow_bg {
  /* padding: 20px; */
  background: transparent !important;
}
