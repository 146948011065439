.cashid_btn {
  background: #3361a3;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  /* margin-top: 205px; */
}
