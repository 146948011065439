.mobile-screen .identification .create-payee-page .mobile .form-control {
    border-radius: initial;
}
.mobile-screen .identification .create-payee-page .mobile .form-select.select {
    width: 80px;
    background-color: #797979;
    border-radius: initial;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
}